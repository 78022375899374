footer {
  bottom: 0;
  width: 100vw;
  background-color: $color-black;
  color: $color-white;
  
  > div#footer {
      width: 100%;
      height: 25vh;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        cursor: pointer;
        font-size: $font-size-large;
        color: $color-primary-light;
        padding: 0 .5rem;
      }
  }

}
