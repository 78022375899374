html {
  scroll-behavior: smooth;
  body {
    font-size: $font-size-ms;
    font-weight: 400;
    line-height: 1.7;
    background-color: $color-white;
    color: $color-black;
    font-family: $font-primary;
    p, li {
      font-family: $font-secondary;
      font-weight: 100;
    }

    .app {
      a {
        transition: 0.2s;
        text-decoration: none;
        color: $color-primary-dark;
        
        &:hover {
          // color: $color-primary-light;
          text-shadow: $shadow-black-white;
        }
      }
    }
  }
}
