header {
  background-color: rgba($color: $color-white, $alpha: 0.98);
  top: 0;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  width: 100vw;
  position: fixed;
  z-index: 11;
  font-size: $font-size-large;;

  .nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .menu-bars-container {
      justify-self: center;
      align-self: flex-end;
      display: inline-block;
      cursor: pointer;
      padding: 0.5rem;

      @include md {
        display: none;
      }

      .menu-bar {
        display: block;
        width: 3.5rem;
        height: 0.5rem;
        background-color: $color-black;
        margin: 0.5rem;
        transition: 0.4s;

        &:nth-last-child(3).menu-clicked {
          transform: rotate(-45deg) translate(-0.9rem, 0.9rem);
          border: solid 0.2rem $color-black;
        }

        &:nth-last-child(2).menu-clicked {
          opacity: 0;
        }

        &:nth-last-child(1).menu-clicked {
          transform: rotate(405deg) translate(-0.6rem, -0.6rem);
          background-color: $color-primary-light;
          border: solid 0.2rem $color-black;
        }
      }
    }

    .menu-web-container {
      display: none;
      a {
        text-decoration: none;
        color: $color-black;
        border-radius: 5rem;
        &:hover {
          text-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
          animation: scalePulse 2s infinite;
        }
      }
    }

    @include md {
      .menu-web-container {
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
    }
  }

  div.nav__dark {
    background-color: $color-black;
    div.menu-web-container {
      a {
        color: $color-white;
        &:hover {
          color: $color-primary-light;
        }
      }
    }

    div.menu-bars-container {
      div.menu-bar {
        background-color: $color-white;

        &:nth-last-child(3).menu-clicked {
          border: solid 0.2rem $color-white;
        }

        &:nth-last-child(2).menu-clicked {
        }

        &:nth-last-child(1).menu-clicked {
          background-color: $color-grey-dark;
          border: solid 0.2rem $color-white;
        }
      }
    }
  }
}
