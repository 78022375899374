.app {
  margin-top: 7vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 0.2rem;

  .divider {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    &[class$="__blk"] {
      background-color: $color-black;
    }

    > div {
      padding: 0.5rem;
      border-radius: 0.2rem;
      width: 80%;
      background-color: $color-primary-light;
    }
  }
}

