/* Reset */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  height: 100vh;
}

body {
  box-sizing: border-box;
  height: inherit;
}
