/* ----------------- FONTS ----------------- */
// Import
@import url("https://fonts.googleapis.com/css?family=Roboto:100,400&display=swap");

// faimilies
$font-primary: "Roboto", sans-serif;
$font-secondary: "Roboto", sans-serif;

// size
$font-size-default: 1.6rem;
$font-size-ms: 2rem;
$font-size-medium: 2.4rem;
$font-size-large: 3.2rem;
$font-size-xlarge: 6.4rem;

/* ----------------- COLORS ----------------- */

$color-primary: #cfa7e3;
$color-primary-light: #e6d5f4;
$color-primary-dark: #ad6ae7;

$color-secondary: #c71414;
$color-secondary-light: #ff3a3a;
$color-secondary-dark: #740404;

$color-tertiary: #82508b;
$color-tertiary-light: #e3b9ed;
$color-tertiary-dark: #5e2f68;

// SHADES
$color-white: #fff;
$color-white-smoke: #f4faff;
$color-white-cream: #fafaf1;
$color-grey-light: #c1c1c1;
$color-grey: #808080;
$color-grey-dark: #3f3f3f;
$color-black: #161617;

/* ----------------- MEDIA QUERIES ----------------- */

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

$shadow-black: 1rem .5rem .5rem $color-black;
$shadow-black-light: .5rem .2rem .8rem rgba(22, 22, 23, 0.827);
$shadow-black-dark: .2rem .1rem .2rem #000;

$shadow-white: -.25rem -.1rem .8rem rgba(255, 255, 255, 0.897);

$shadow-black-white: $shadow-black-light, $shadow-white;

$shadow-primary: 3px 3px 0 $color-white, -1px -1px 0 $color-white, 1px -1px 0 $color-white,
-1px 1px 0 $color-white, 1px 1px 0 $color-white;