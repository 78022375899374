.connect-tab {
  width: 10rem;
  right: 0;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 3;

  // Social media
  .social-media {
    height: 40rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background-color: transparent;

    .social-media-item {
      padding: 2rem;
      cursor: pointer;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      margin: 0.5rem 0;
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
      box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      background-color: $color-primary-light;

      @for $i from 1 to 6 {
        &:nth-child(#{$i}) {
          animation: morphing #{($i * 0.1) + 15s} infinite;
          animation-delay: #{($i * 0.5) + 0.3s};
        }
      }

      &:hover {
        animation-play-state: paused;
      }
    }
  }

  .animate-social-media {
    animation: moveInRight 1s;
  }

  // Connect Button (opens social media links)
  .btn-connect {
    cursor: pointer;
    border: 0;
    padding: 0.7rem 1.5rem;
    background-color: $color-primary-light;
    // border-radius: 0.01rem;
    color: $color-black;

    &:focus {
      outline: none;
    }
  }

  .btn-hiding-content {
    animation: wiggle 2s infinite;
    &:hover {
      animation: pulse 4s infinite;
    }
  }
}
