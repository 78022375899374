.dropdown {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 3;
  text-align: center;
  transition: 0.4s;

  &:active {
    animation: fadeOut 0.4s;
  }

  a {
    opacity: 0.97;
    background-color: $color-white;
    font-family: $font-secondary;
    font-size: 6rem;
    color: $color-black;
    text-decoration: none;
    display: block;
    padding: 3rem;
    box-shadow: inset 0 0 0 0.01rem $color-black;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    transition: all ease 0.4s;

    @for $i from 1 to 6 {
      &:nth-child(#{$i}) {
        animation: moveInBottom #{$i * 0.1s};
      }
    }

    &:not(:last-child) {
      border-bottom: 0.1rem solid $color-tertiary-dark;
    }

    &:hover {
      box-shadow: inset 100vw 0 0 0 $color-black;
      color: $color-white;
    }
  }
}

.nav__dark {
  .dropdown {
    a {
      background-color: $color-black;
      color: $color-white;

      &:not(:last-child) {
        border-bottom: 0.1rem solid $color-primary-light;
      }

      &:hover {
        box-shadow: inset 100vw 0 0 0 $color-white;
        color: $color-black;
      }
    }
  }
}
