#projects {
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: $color-white;
  background-color: $color-black;
  font-size: $font-size-default;
  @include lg {
    font-size: $font-size-ms;
  }

  .projects-container {
    height: 120rem;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include lg {
      div.awssld {
        width: 80vw;
      }
    }

    @include xl {
      width: 80vw;
    }

    div.awssld__content {
      div {
        display: flex;
        height: inherit;
        width: inherit;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-end;
        margin-bottom: 3rem;
      }
    }

    .awssld {
      --slider-height-percentage: 60%;
      --slider-transition-duration: 700ms;
      --organic-arrow-thickness: 4px;
      --organic-arrow-border-radius: 0px;
      --organic-arrow-height: 40px;
      --organic-arrow-color: #cfa7e3;
      --control-button-width: 10%;
      --control-button-height: 25%;
      --control-button-background: transparent;
      --control-bullet-color: #fff;
      --control-bullet-active-color: #fff;
      --loader-bar-color: #cfa7e3;
      --loader-bar-height: 6px;
    }

    [class^="single-project"] {
      position: relative;
      background-color: rgba($color: $color-black, $alpha: 0.5);
      border-radius: 0.5rem;
      padding: 0.3rem;
      color: $color-white;
      z-index: 3;
      width: 20%;

      &[class$="__title"] {
        // top: 1rem;
        // margin-right: 10rem;
        color: $color-primary-light;
        font-weight: 500;
      }

      &[class$="__description"] {
        // top: 1rem;
        // margin-right: 10rem;
      }

      &[class$="__live-link"],
      &[class$="__source-code-link"] {
        cursor: pointer;
        &:hover {
          background-color: rgba($color: $color-black, $alpha: 0.5);
          color: $color-primary-light;
        }
      }
    }
  }
}
