// live Buttons
#live-feed {
  button {
    border: 0;
    height: 2.5rem;
    padding: 0.7rem 1.5rem;
    background-color: $color-primary;
    cursor: pointer;
    border-radius: 0.1rem;
    color: $color-grey-dark;

    &:disabled {
      background-color: $color-grey-light;
      cursor: not-allowed;
    }
  }
}
button {
  &:focus {
    outline: none;
  }
}
