#archives {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: $color-black;
  color: $color-white;
  font-size: $font-size-large;

  @include md {
    font-size: $font-size-medium;
  }

  @include lg {
    font-size: $font-size-default;
    .wayback-p {font-size:$font-size-medium}
  }

  > h1 {
    color: $color-black;
  }

  > div {
    height: 65rem;
    width: 100%;
  }

  .archive-list-container,.archive-view-container
  {
    animation: fadeIn 2s;
  }

  .archive-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
      flex-grow: 1;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .versions-list-item {
        font-size: $font-size-large;
        cursor: pointer;

        &[class$="__current"] {
          cursor: text;
        }

        &:not(:last-child) {
          padding-right: .5rem;
          transition: 0.4s ease;
          &::before {
            transition: .4s ease-in;
            color: $color-black;
            content: "❧ ";
          }

          &:hover {
            color: $color-black;
            text-shadow: $shadow-white, $shadow-white;
            &::before {
              text-shadow: $shadow-white;
              // color: $color-red;
              content: "❧ ";
            }
          }
        }
      }
    }
  }

  .archive-view-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    .archive-view--container__details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        cursor: pointer;
      }

      .archive-view--img {
        width: 100%;
        @include md {
          width: 80%;
        }
        @include lg {
          width: 70%;
        }
      }
    }

    .archive-view--container__btn--back {
      button.btn--back {
        border: 0;
        background-color: $color-primary-light;
        color: $color-black;
        cursor: pointer;
        border-radius: 0.1rem;
        padding: 0.7rem 0.5rem;
        margin-left: 1rem;

        code {
          padding: 0.5rem;
        }
      }
    }
  }
}
