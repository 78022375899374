#live-feed {
    width: 30rem;
    right: 100;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 3;
    display: none;

    .contribution-card {
        background-color: $color-white;
        width: 25rem;
        padding: .5rem .4rem;
        border: .1rem solid $color-primary-dark;
        border-radius: .5rem;
        box-shadow: $shadow-black-white;
        animation: fadeIn 1s;
    }
}
