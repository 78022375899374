.live-feed-project-card {
  min-height: 40rem;
  min-width: 35rem;
  width: 40rem;
  margin: 1rem 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 0.1rem;
  background-color: $color-black;
  color: $color-primary-light;
  white-space: nowrap;
  transition: ease-in;
  
  @for $i from 1 to 4 {
    &:nth-child(#{$i}) {
      animation: fadeIn #{$i * 0.2s};
    }
  }
  
  code {
    white-space: normal;
  }
  
  h2 {
    color: $color-primary;
    // overflow: wrap;
  }

  &:hover {
    a {
      code {
        font-weight: 700;
      }
    }
  }
}
