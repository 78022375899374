#about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  > h1 {
    color: $color-white;
  }

  .about-content {
    height: 85vh;
    width: inherit;
  }

  .about-toggle {
    cursor: pointer;
  }

  .about-me,
  .about-you {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-me {
    #alex-photo {
      height: 15rem;
      border: solid 0.5rem $color-black;
      border-radius: 1%;
      
      @include md {
        height: 20rem;
      }
      
      @include lg {
        height: 25rem;
      }
    }
    
    .about-me__content {
      margin-top: 2.5rem;
      overflow-y: scroll;
    }

    p {
      margin: 2rem 2rem 0;

      @include sm {
        margin: 2rem 5rem 0;
      }

      @include md {
        margin: 2rem 10rem 0;
      }

      @include lg {
        margin: 2rem 20rem 0;
      }

      @include xl {
        margin: 2rem 25rem 0;
      }
    }
  }
  .about-you {
    // height: 85vh;
  }
}
